export interface IMonitoringEntry {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: string;
  errorMessage?: string;
  createdAt: string;
  apiName: string;
  infoSessionDate?: string;
  infoSessionTitle?: string;
  infoSessionHra?: string;
}

export interface IMonitoringEntriesMetaInfo {
  totalRecords: number;
  currentPage: number;
  totalFailure: number;
  totalSuccess: number;
  totalIncomplete: number;
  data: IMonitoringEntry[];
  totalPages: number;
}

export const monitoringEmptyMetaInfo: IMonitoringEntriesMetaInfo = {
  totalRecords: 0,
  currentPage: 1,
  totalSuccess: 0,
  totalFailure: 0,
  totalIncomplete: 0,
  data: [],
  totalPages: 1,
};

export const emptyMonitoringEntry: IMonitoringEntry = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  status: '',
  createdAt: '',
  apiName: '',
};

export const MONITORING_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  INCOMPLETE: 'incomplete',
};

export const API_NAME = {
  SIGN_UP: 'signUp',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgotPassword',
};
