import React, { useEffect, useState, useMemo } from 'react';
import { Autocomplete, Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import CustomTable from '../CustomTable';
import CustomDateRangePicker from '../DateRangePicker/CustomDateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import {
  API_NAME,
  IMonitoringEntriesMetaInfo,
  IMonitoringEntry,
  MONITORING_STATUS,
  monitoringEmptyMetaInfo,
} from '../../store/monitoringSystem/monitoringSystemModal';
import { fetchMonitoringEntriesByDateRange, searchMonitoringEntry } from '../../services/monitoringSystem.service';
import { Loader } from '../Loader/Loader';
import { CreateColumnHeader } from '../CreateColumnHeader/CreateColumnHeader';
import { CreateColumnCell } from '../CreateColumnCell/CreateColumnCell';
import { COLORS } from '../../utils/colors';
import { EMAIL_REGEX } from '../../utils/regex';

const monitoringApiList = [
  { label: 'Sign Up', value: 'signUp' },
  { label: 'Login', value: 'login' },
  { label: 'Forgot Password', value: 'forgotPassword' },
];

const MonitoringSystem = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchPaginationModel, setSearchPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [listData, setListData] = useState<Array<IMonitoringEntry>>([]);
  const [searchQueryType, setSearchQueryType] = useState<string>('name');
  const [metaInfo, setMetaInfo] = useState<IMonitoringEntriesMetaInfo>(monitoringEmptyMetaInfo);
  const [selectedApi, setSelectedApi] = useState<{ label: string; value: string }>({
    label: 'Sign Up',
    value: 'signUp',
  });

  // Define base columns
  const baseColumns = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => params.row.firstName + ' ' + params.row.lastName,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'apiName',
      headerName: 'API name',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) =>
        params.row.apiName === API_NAME.SIGN_UP
          ? 'Sign Up'
          : params.row.apiName === API_NAME.LOGIN
          ? 'Login'
          : params.row.apiName === API_NAME.FORGOT_PASSWORD
          ? 'Forgot Password'
          : '',
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      sortable: true,
      width: 250,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        if (params.row.status === MONITORING_STATUS.SUCCESS) {
          return CreateColumnCell(
            params.row.status,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.lightGreen,
            COLORS.palette.green
          );
        } else if (params.row.status === MONITORING_STATUS.FAILURE) {
          return CreateColumnCell(
            params.row.status,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.lightRed,
            COLORS.palette.red
          );
        } else {
          return CreateColumnCell(
            params.row.status,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.lightBlue,
            COLORS.palette.blue
          );
        }
      },
    },
    {
      field: 'createdAt',
      headerName: 'Processed At',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => dayjs(params.row.createdAt).format('MM/DD/YYYY'),
    },
  ];

  // Define InfoSession columns
  const infoSessionColumns = [
    {
      field: 'infoSessionDate',
      headerName: 'Infosession Date',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) =>
        params.row.infoSessionDate ? dayjs(params.row.infoSessionDate).format('MM/DD/YYYY') : '',
    },
    {
      field: 'infoSessionTitle',
      headerName: 'Infosession Title',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'infoSessionHra',
      headerName: 'Infosession HRA',
      type: 'string',
      sortable: true,
      width: 250,
    },
  ];

  const columns = useMemo(() => {
    if (selectedApi.value === API_NAME.SIGN_UP) {
      return [...baseColumns, ...infoSessionColumns];
    }
    return baseColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApi.value]);

  const handleStartDateChange = (newDate: Dayjs | null) => {
    setStartDate(newDate);
  };

  const handleEndDateChange = (newDate: Dayjs | null) => {
    setEndDate(newDate);
  };

  const fetchData = async () => {
    if (startDate && endDate && (startDate.isBefore(endDate) || startDate.isSame(endDate)) && selectedApi.value) {
      setLoading(true);
      setIsSearchMode(false);
      setSearchQuery('');
      const result = await fetchMonitoringEntriesByDateRange(
        paginationModel.page + 1,
        startDate,
        endDate,
        selectedApi.value
      );
      if (result) {
        setMetaInfo(result);
      }
      if (result?.data) {
        setListData(result.data as Array<IMonitoringEntry>);
      }
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (searchQuery && searchQueryType && selectedApi.value) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchMonitoringEntry(
        searchPaginationModel.page + 1,
        selectedApi.value,
        searchQuery,
        searchQueryType
      );
      if (result) {
        setMetaInfo(result);
      }
      if (result?.data) {
        setListData(result.data);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isSearchMode) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, startDate, endDate, isSearchMode, selectedApi.value]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPaginationModel.page]);

  useEffect(() => {
    if (!searchQuery) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    (async () => {
      if (!isSearchMode) {
        fetchData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} mt={4}>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid item xs={12} sm={12} lg={6} width={'100%'}>
          <Autocomplete
            value={selectedApi}
            size='small'
            freeSolo
            disableClearable
            options={monitoringApiList?.map((item: any) => ({
              label: item.label,
              value: item.value,
            }))}
            getOptionLabel={(option: any) => {
              return option.label;
            }}
            onChange={(event, object) => {
              if (typeof object === 'string') {
                setSelectedApi({ label: object, value: object });
              } else {
                setSelectedApi(object);
              }
            }}
            renderInput={(params) => <TextField {...params} label='Select Api' />}
          />
        </Grid>
        <Box display={selectedApi.value ? 'block' : 'none'}>
          <Grid item xs={12} sm={12} lg={12} mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CustomDateRangePicker
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              disableStartDatePast={'2025-02-11'}
              defaultStarDate={dayjs()}
              defaultEndDate={dayjs()}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} mt={2}>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '75%',
                  md: '100%',
                },
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                alignItems: 'center',
                justifyContent: 'space-between',
                textAlign: {
                  xs: 'center',
                  sm: 'left',
                },
                gap: 5,
              }}>
              <Tooltip arrow title='Number of clients who successfully completed the action'>
                <Typography color={'green'} variant='h6'>
                  Total Success: {metaInfo?.totalSuccess || 0}
                </Typography>
              </Tooltip>

              <Tooltip arrow title='Number of clients who attempted the action but encountered an error'>
                <Typography color={'error'} variant='h6'>
                  Total Failures: {metaInfo?.totalFailure || 0}
                </Typography>
              </Tooltip>

              <Tooltip arrow title='Number of clients who started the action but did not complete it'>
                <Typography color={'#42A5F5'} variant='h6'>
                  Total Incompletes: {metaInfo?.totalIncomplete || 0}
                </Typography>
              </Tooltip>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: '-40px' }}>
        {loading ? (
          <Loader />
        ) : (
          <CustomTable
            hidePrimaryBtn={true}
            onFilterListChange={() => {}}
            rows={listData}
            columns={columns}
            loading={loading}
            rowCount={metaInfo?.totalRecords || 0}
            paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
            onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
            filterList={[]}
            onChangeSearchQuery={(val: string) => {
              setSearchQuery(val);
              if (EMAIL_REGEX.test(val)) {
                setSearchQueryType('email');
              } else {
                setSearchQueryType('name');
              }
            }}
            handleSearch={() => handleSearch()}
            searchQuery={searchQuery}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default MonitoringSystem;
